<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Translations" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Category"
                  v-model="category"
                  readonly
                />
                <va-input
                  label="Message"
                  v-model="message"
                  readonly
                />
                <va-card
                  title="Translations"
                >
                  <va-input
                    label="uk"
                    v-model="uk"
                    type="textarea"
                  />
                  <va-input
                    label="ru"
                    v-model="ru"
                    type="textarea"
                  />
                  <va-input
                    label="en"
                    v-model="en"
                    type="textarea"
                  />
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      category: '',
      message: '',
      uk: '',
      ru: '',
      en: '',

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/translation/${this.$attrs.id}?lang=${this.locale}`, {
        translation: {
          uk: this.uk,
          ru: this.ru,
          en: this.en,
        },
      })
        .then(response => {
          this.$router.push({ name: 'shkil-settings-translations' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/translation/${this.$attrs.id}?lang=${this.locale}`)
        .then(response => {
          console.log(response.data)
          this.category = response.data.category
          this.message = response.data.message

          this.uk = response.data.translation.uk
          this.ru = response.data.translation.ru
          this.en = response.data.translation.en
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
